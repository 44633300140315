@charset 'UTF-8';
@import "/Users/quanbui/website/vhost/misawa/src/scss/utility/_media-queries.scss";
@import "/Users/quanbui/website/vhost/misawa/src/scss/utility/_mixin.scss";


.top{
  .bgParallax{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    border: none;
    padding: 0;
    margin: 0;
    z-index: -1;
    &__inner{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow: hidden;
      border: none;
      padding: 0;
      margin: 0;
      background: url(../../img/top/bg.jpg) center center/ 100% auto no-repeat;
    }
    @include SP{
      &__inner{
        background: url(../../img/top/bg_sp.jpg) center center/ 100% auto no-repeat;
      }
    }
  }
  .title{
    margin: 0 auto 45px;
  }
  .secBG{
    margin-bottom: 100px;
    img{
      display: block;
      width: 100%;
    }
    @include SP{
      margin-bottom: 50px;
      img{
        height: 150px;
        object-fit: cover;
      }
    }
  }
  &__visual{
    position: relative;
    .slider{
      &__item{
        position: relative;
        &__bg{
          background-size: cover;
          background-position: center center;
        }
        &__img{
          position: absolute;
          right: 23px;
          top: 40px;
          display: block;
          img{
            width: 354px;
            display: block;
            margin: 0 auto 20px;
            &:last-child{
              margin-bottom: 0;
              width: 200px;
            }
          }
        }
      }
    }
    
    @include SP{
      .slider{
        &__item{
          &__img{
            top: 10px;
            right: 5px;
            img{
              width: 177px;
              margin-bottom: 10px;
              &:last-child{
                width: 100px;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
  &__sec01{
    padding-top: 80px;
    background: #fff url(../../img/common/bg_pattern.jpg) top center/ 100% auto repeat-y;
    @include clearfix();
    margin-bottom: 300px;
    .lstService{
      margin-bottom: 154px;
      li{
        margin-bottom: 140px;
        &:last-child{
          margin-bottom: 0;
        }
        .blockService{
          @include clearfix();
          &__top{
            display: flex;
            figure{
              width: 66.66%;
              min-height: 640px;
              order: 2;
              @include screen(768px, 1400px){
                min-height: 600px;
              }
              img{
                display: block;
                width: 100%;
              }
            }
            .ttl_wrap{
              width: 33.34%;
              order: 1;
              &__inner{
                width: 100%;
                max-width: 350px;
                margin-left: auto;
                padding: 0 50px;
                .title{
                  margin: 0 auto;
                }
              }
            }
          }
          &__bottom{
            position: relative;
            width: calc(33.34% + 86px);
            margin-top: -160px;
            z-index: 99;

            @include screen(768px, 1024px){
              width: 66.66%;
            }
            .boxWhite{
              background: #fff;
              -webkit-box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.5);
              box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.5);
              &__inner{
                width: 100%;
                max-width: 500px;
                margin-left: auto;
                padding: 54px 50px 40px;
                .ttl{
                  @include font(22,37,0,500);
                  margin-bottom: 28px;
                }
                .btn{
                  margin-top: 41px;
                }
              }
            }
          }
        }

        &:nth-child(2n){
          .blockService{
            &__top{
              figure{
                order: 1;
              }
              .ttl_wrap{
                order: 2;
                &__inner{
                  margin-left: 0;
                  margin-right: auto;
                  padding: 0 50px;
                }
              }
            }
            &__bottom{
              float: right;
              .boxWhite{
                &__inner{
                  margin-left: 0;
                  margin-right: auto;
                }
              }
            }
          }
        }
      }
    }
    @include SP{
      padding-top: 40px;
      margin-bottom: 150px;
      .lstService{
        margin-bottom: 77px;
        li{
          margin-bottom: 70px;
          &:first-child{
            .title{
              min-height: 226px;
            }
          }
          .blockService{
            &__top{
              flex-direction: column;
              figure{
                width: 100%;
                min-height: auto;
                order: 1;
                margin-bottom: 20px;
                
              }
              .ttl_wrap{
                width: 100%;
                order: 2;
                &__inner{
                  margin: 0 auto !important;
                }
              }
            }
            &__bottom{
              width: 100%;
              margin-top: 20px;
              padding: 0 15px;
              .boxWhite{
                &__inner{
                  padding: 27px 25px;
                  margin: 0 auto;
                  .ttl{
                    margin-bottom: 14px;
                  }
                  .btn{
                    margin-top: 25px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &__sec02{
    margin-bottom: 300px;
    padding: 100px 0;
    background: #fff;
    .lstTwoCols{
      display: flex;
      margin: 0 -20px;
      &>li{
        width: 50%;
        padding: 0 20px;
        &:last-child{
          padding-left: 78px;
          @include screen(768px, 1024px){
            padding-left: 30px;
          }
        }
        
        .lstTopics{
          margin-bottom: 59px;
          li{
            padding: 22px 0 18px;
            border-bottom: 1px solid rgba(51,51,51,.5);
            &:first-child{
              padding-top: 0;
              margin-top: -5px;
            }
            a{
              display: flex;
              .date{
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 100px;
              }
            }
          }
        }
        .lstInsta{
          display: flex;
          flex-flow: row wrap;
          margin: 0 -10px 55px;
          li{
            width: 33.33%;
            padding: 0 10px;
            margin-top: 20px;
            &:nth-child(1),&:nth-child(2),&:nth-child(3){
              margin-top: 0;
            }
          }
        }
        .btnFacebook{
          display: flex;
          align-items: center;
          width: 240px;
          height: 72px;
          border: 2px solid #1877F2;
          border-radius: 37px;
          margin: 0 auto;
          span{
            display: block;
            padding-left: 103px;
            @include font(22,24,0,500);
            background: url(../../img/common/icon/ico_facebook.svg) left 47px top 7px/ 40px auto no-repeat;
            color: #1877F2;
            margin-top: -8px;
            em{
              font-size: 15px;
              line-height: 18px;
              font-style: normal;
            }
          }
        }
      }
    }
    @include SP{
      margin-bottom: 150px;
      padding: 80px 0;
      .lstTwoCols{
        flex-flow: row wrap;
        margin: 0;
        &>li{
          width: 100%;
          padding: 0;
          margin-bottom: 70px;
          &:last-child{
            padding-left: 0;
            margin-bottom: 0;
          }
          
          .lstTopics{
            margin-bottom: 40px;
            li{
              padding: 15px 0 13px;
              a{
                flex-direction: column;
                .date{
                  flex-basis: auto;
                }
              }
            }
          }
          .lstInsta{
            display: flex;
            flex-flow: row wrap;
            margin: 0 -10px 40px;
            li{
              width: 33.33%;
              padding: 0 10px;
              margin-top: 20px;
              &:nth-child(1),&:nth-child(2),&:nth-child(3){
                margin-top: 0;
              }
            }
          }
          .btnFacebook{
            display: flex;
            align-items: center;
            width: 240px;
            height: 72px;
            border: 2px solid #1877F2;
            border-radius: 33px;
            margin: 0 auto;
            span{
              display: block;
              padding-left: 103px;
              @include font(22,24,0,500);
              background: url(../../img/common/icon/ico_facebook.svg) left 47px top 7px/ 40px auto no-repeat;
              color: #1877F2;
              margin-top: -8px;
              em{
                font-size: 15px;
                line-height: 18px;
                font-style: normal;
              }
            }
          }
        }
      }
    }
  }
  &__sec03{
    padding: 100px 0 80px;
    margin-bottom: 300px;
    background: #fff;
    .lstGallery{
      display: flex;
      flex-flow: row wrap;
      margin: 0 -20px;
      li{
        width: 25%;
        padding: 0 20px;
        margin-bottom: 40px;
        cursor: pointer;
      }
    }
    @include SP{
      margin-bottom: 150px;
      padding: 80px 0 60px;
      .lstGallery{
        display: flex;
        flex-flow: row wrap;
        margin: 0 -10px;
        li{
          width: 50%;
          padding: 0 10px;
          margin-bottom: 20px;
        }
      }
    }
  }
  &__sec04{
    padding: 100px 0 160px;
    background: #fff;
    .map{
      iframe{
        width: 100% !important;
        height: 450px !important;
      }
    }
    .container{
      max-width: 956px;
    }
    .lstDirection{
      &__item{
        margin-top: 50px;
        &__ttl{
          @include font(22,37,0,500);
          padding-bottom: 12px;
          border-bottom: 1px solid rgba(51,51,51,.5);
          margin-bottom: 40px;
        }
        &__img{
          img{
            display: block;
            width: 100%;
          }
        }
        &__note{
          background: #F7EFE2;
          padding: 36px 57px 42px;
          margin-top: 40px;
          .ttl{
            @include font(22,37,0,500);
            margin-bottom: 8px;
          }
          p{
            @include font(15,25,0,500);
          }
        }
      }
    }
    @include SP{
      padding: 80px 0;
      .map{
        iframe{
          height: 250px !important;
        }
      }
      .lstDirection{
        &__item{
          margin-top: 40px;
          figure{
            overflow: auto;
            padding-bottom: 20px;
            &::-webkit-scrollbar {
              height: 5px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: $green;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }
            img{
              width: 926px;
              max-width: none;
            }
          }
          &__ttl{
            @include font(20,32,0,500);
            padding-bottom: 8px;
            margin-bottom: 30px;
          }

          &__note{
            background: #F7EFE2;
            padding: 18px 27px 22px;
            margin-top: 25px;
            .ttl{
              @include font(18,30,0,500);
            }
          }
        }
      }
    }
  }
  .popup_imgs{
    &__slider{
      max-width: 830px;
    }
    .wrap_container{
      max-width: 830px;
    }
    
  }
}